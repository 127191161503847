import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Portal } from "react-portal";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import { GroupApi } from "pages/ChatAdmin/_api/group";
import { useChat } from "_common/component/ChatProvider";

function ModalConfirmDelete({ onClose, data }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();
  const { pushChat } = useChat();

  const { mutate: onDelete } = useMutation(
    (variables) => GroupApi.delete({ id: data?.id }),
    {
      onSuccess: (res) => {
        onClose();
        pushChat({
          type: "delete_group",
          group_code: data?.group_code,
        });
        NotificationManager.success(i18next.t("group_successfully_deleted"));
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const { mutate: onLeave } = useMutation(
    (variables) =>
      GroupApi.deleteMember({
        params: {
          id: data?.id,
          user_id: user?.id,
        },
      }),
    {
      onSuccess: (res) => {
        onClose();
        pushChat({
          type: "delete_member",
          group_code: data?.group_code,
          user_id: user?.id,
        });
        NotificationManager.success(i18next.t("successfully_left_the_group"));
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = () => {
    if (user?.id === data?.created_by) {
      onDelete();
    } else {
      onLeave();
    }
  };

  return (
    <Portal>
      <div className="modal">
        <div className="modal-mask" />
        <div className="modal-root">
          <div className="modal-title">
            {user?.id === data?.created_by ? (
              <h4>{i18next.t("delete_group")}</h4>
            ) : (
              <h4>{i18next.t("leave_group")}</h4>
            )}
          </div>
          <div className="modal-content pb-24">
            {user?.id === data?.created_by ? (
              <p className="mb-2">
                {i18next.t("chat_text_1")} <b>{data?.name}</b>
              </p>
            ) : (
              <p className="mb-2">
                {i18next.t("chat_text_2")} <b>{data?.name}</b>
              </p>
            )}
            <p>
              <i>{i18next.t("chat_text_3")}</i>
            </p>
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button type="button" className="btn-cancel" onClick={onClose}>
                {i18next.t("close")}
              </button>
              <button
                type="button"
                className="btn-contact-cskh"
                onClick={handleSubmit}
              >
                {i18next.t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalConfirmDelete;
