import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Portal } from "react-portal";
import i18next from "i18next";
import { GroupApi } from "pages/ChatAdmin/_api/group";
import { useChat } from "_common/component/ChatProvider";
import { NotificationManager } from "react-notifications";

function ModalEditGroup({ onClose, data }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general },
  } = useStores();
  const { pushChat } = useChat();

  const [nameGroup, setNameGroup] = useState("");

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) => GroupApi.update({ id: data?.id, params: variables }),
    {
      onSuccess: (res) => {
        onClose();
        pushChat({
          type: "edit_group",
          group_code: data?.code,
        });
        NotificationManager.success(i18next.t("group_edited_successfully"));
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    if (!data) return;
    setNameGroup(data?.name);
  }, [data]);

  const handleChangeInput = (event) => {
    setNameGroup(event?.target?.value);
  };

  const handleSubmit = () => {
    if (!nameGroup)
      return NotificationManager.error(i18next.t("please_enter_a_group_name"));

    const req = {
      name: nameGroup,
    };

    onSubmit(req);

    return null;
  };

  return (
    <Portal>
      <div className="modal">
        <div className="modal-mask" />
        <div className="modal-root">
          <div className="modal-title">
            <h4>{i18next.t("edit_group_information")}</h4>
          </div>
          <div className="modal-content pb-24">
            <p className="mb-2">
              <b>{i18next.t("group_name")}</b>
            </p>
            <input
              type="text"
              className="form-control bg-light border-light"
              placeholder={i18next.t("enter_group_name")}
              value={nameGroup}
              onChange={handleChangeInput}
            />
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button type="button" className="btn-cancel" onClick={onClose}>
                {i18next.t("close")}
              </button>
              <button
                type="button"
                className="btn-contact-cskh"
                onClick={handleSubmit}
              >
                {i18next.t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalEditGroup;
