import React from "react";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

const UnAuthLayout = observer(() => (
  <div className="mobile-layout">
    <Outlet />
  </div>
));

export default UnAuthLayout;
