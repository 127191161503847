import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import { Outlet, useSearchParams } from "react-router-dom";
import ChatProvider from "_common/component/ChatProvider";
import { AuthApi } from "states/api";
import { useQuery } from "@tanstack/react-query";
import NotifyProvider from "_common/component/NotifyProvider";
import { serverUrl } from "_common/constants/common";

const SupportLayout = observer(() => {
  const { authStore } = useStores();
  const [params] = useSearchParams();
  const token = params.get("token");

  const urlSound = useMemo(
    () => ({
      chat: `${serverUrl}/assets/sound-chat.mp3?v=${authStore.version}`,
    }),
    [authStore.version]
  );

  useQuery(["get_profile"], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!token,
    retry: 3,
    onSuccess: (res) => {
      authStore.updateUser({ user: res?.data });
    },
  });

  useEffect(() => {
    if (!token) return;
    authStore.updateToken(token);
  }, [token]);

  return (
    <ChatProvider user={authStore?.user}>
      <NotifyProvider>
        <Outlet />
        <audio id="sound3" muted autoPlay>
          <source src={urlSound.chat} />
        </audio>
      </NotifyProvider>
    </ChatProvider>
  );
});

export default SupportLayout;
