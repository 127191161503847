import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import i18next from "i18next";
import { useQuery } from "@tanstack/react-query";
import { FriendApi } from "pages/ChatAdmin/_api/friend";
import { debounce, get } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ListDataUser from "./ListDataUser";

function SearchUser({ onClose, api_type, group, isSelect, onSelect, lstUser }) {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const [params, setParams] = useState({});

  const { data, refetch } = useQuery(
    ["user", "list_user", params],
    () =>
      FriendApi.getListUser({
        params: {
          page: 1,
          limit: 13,
          api_type,
          group_id: group?.id,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    ref?.current?.focus?.();
  }, [ref]);

  const getListUser = debounce((keyword) => {
    setParams({ keyword });
  }, 1000);

  const handleChangeInput = (event) => {
    getListUser(event?.target?.value);
  };

  return (
    <>
      <div className="px-2 mb-2">
        <div className="search-box-group">
          <div className="search-box">
            <input
              ref={ref}
              type="text"
              className="form-control bg-light border-light"
              placeholder={i18next.t("search_for_users")}
              onChange={handleChangeInput}
            />
          </div>
          {!isSelect && (
            <button
              type="button"
              className="btn btn-close-search"
              onClick={() => onClose()}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          )}
        </div>
      </div>
      <div className="px-2 py-1">
        <p className="mb-0">
          <b>{i18next.t("search_results")}</b>
        </p>
      </div>
      <div className="chat-message-list">
        <div className="list-unstyled chat-list chat-user-list">
          <ListDataUser
            data={get(data, "data", [])}
            onClose={onClose}
            isSelect={isSelect}
            isRedirect={!isSelect}
            onSelect={onSelect}
            ids={lstUser?.map((obj) => obj?.id)}
          />
        </div>
      </div>
    </>
  );
}

export default SearchUser;
