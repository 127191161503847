import { get } from "lodash";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class AuthStore {
  token = "";

  user = null;

  device = "";

  languages = [];

  logo = "";

  version = "1.0.0";

  language = "en";

  constructor() {
    this.initSource();
  }

  get authenticated() {
    // TODO: This logic can be changed on demand
    return !!this.token;
  }

  initSource = () => {
    try {
      const dataStorage = JSON.parse(window.localStorage.getItem("AuthStore"));
      if (dataStorage?.token) {
        this.device = dataStorage?.device;
        this.updateToken(dataStorage?.token);
        this.updateUser(dataStorage);
      }
    } catch (error) {
      console.error(error);
    }
  };

  clear = () => {
    this.token = "";
    this.user = null;
  };

  setInitData = (data) => {
    if (!this.device) {
      this.device = data?.device_code;
    }
    const general = data?.general || [];
    this.version = data?.version || [];

    if (Array.isArray(general)) {
      const logoData = general?.find((obj) => obj?.name === "logo_dark");
      if (logoData) {
        this.logo = logoData?.image_url;
      }
    }

    const newsValue = {};

    general.forEach((item, index) => {
      newsValue[item.name] = item.value;
      if (item.value === "0") {
        newsValue[item.name] = false;
      }
    });

    this.general = newsValue;
  };

  updateToken = (token) => {
    this.token = token || this.token;
  };

  setLanguage = (value) => {
    this.language = value;
    window.location.reload();
  };

  updateUser = (data) => {
    const tokens = get(data.user, "tokens", []) || [];
    this.user = data.user;
    this.token = data?.token || this.token;
    this.my_bank = get(data, "user.banks[0]", {}) || this.my_bank;
    this.tokens = tokens;
    this.user_wallet = tokens?.find((obj) => obj.symbol === "vnd");
    this.game_wallet = tokens?.find((obj) => obj.symbol === "game");
  };
}
