import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import PageLoading from "_common/component/PageLoading";
import "pages/ChatClient/_styles/scss/style.scss";

import MessageBoard from "./MessageBoard";

const ChatClientPage = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="support-page">
      <div className="page-content page-content-support">
        <div className="chat-wrapper d-lg-flex gap-1">
          {!isLoading ? <MessageBoard /> : <PageLoading />}
        </div>
      </div>
    </div>
  );
});

export default ChatClientPage;
