import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Portal } from "react-portal";
import i18next from "i18next";
import { GroupApi } from "pages/ChatAdmin/_api/group";
import { useChat } from "_common/component/ChatProvider";
import { GroupUserApi } from "pages/ChatAdmin/_api/groupUser";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";

function ModalDetailUser({ onClose, group, data }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();
  const { pushChat } = useChat();

  const { data: userData, refetch } = useQuery(
    ["group_user", "detail"],
    () =>
      GroupUserApi.getDetail({
        params: {
          user_id: data?.user_id,
          group_id: group?.id,
        },
      }),
    {
      staleTime: 300000,
      enabled: false,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const { mutate: onMuteChat } = useMutation(
    (variables) =>
      GroupApi.blockMember({
        params: {
          id: group?.id,
          user_id: data?.user_id,
          is_block: userData?.is_block === 0 ? 1 : 0,
        },
      }),
    {
      onSuccess: (res) => {
        onClose();
        pushChat({
          type: "block_member",
          group_code: group?.group_code,
          user_id: data?.user_id,
        });
        NotificationManager.success(
          userData?.is_block === 0
            ? i18next.t("member_successfully_blocked")
            : i18next.t("allow_members_to_chat_successfully")
        );
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );
  const { mutate: onLeave } = useMutation(
    (variables) =>
      GroupApi.deleteMember({
        params: {
          id: group?.id,
          user_id: data?.user_id,
        },
      }),
    {
      onSuccess: (res) => {
        onClose();
        pushChat({
          type: "delete_member",
          group_code: group?.group_code,
          user_id: data?.user_id,
        });
        NotificationManager.success(i18next.t("kick_user_from_group"));
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  return (
    <Portal>
      <div className="modal">
        <div className="modal-mask" />
        <div className="modal-root">
          <div className="modal-title">
            <h5>{i18next.t("user_information")}</h5>
          </div>
          <div className="modal-content pb-24">
            <div className="d-flex align-items-center mb-2">
              <div className="avatar avatar-s me-3">
                <img src={userData?.user?.avatar_url} alt="" />
              </div>
            </div>
            <h5 className="mb-1">
              <b>ID: </b>
              {userData?.user?.id}
            </h5>
            <h5 className="mb-1">
              <b>{i18next.t("username")}: </b>
              {userData?.user?.full_name}
            </h5>
            {user?.id === group?.created_by && (
              <>
                <h5 className="mb-1">
                  <b>{i18next.t("username_2")}: </b>
                  {userData?.user?.username}
                </h5>
                <div className="d-flex align-items-center gap-2 mt-12">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={onLeave}
                  >
                    {i18next.t("kick_from_group")}
                  </button>
                  <button
                    type="button"
                    className={classNames("btn", {
                      "btn-info": userData?.is_block === 0,
                      "btn-success": userData?.is_block === 1,
                    })}
                    onClick={onMuteChat}
                  >
                    {userData?.is_block === 0
                      ? i18next.t("no_chat")
                      : i18next.t("allow_chat")}
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button type="button" className="btn-cancel" onClick={onClose}>
                {i18next.t("close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalDetailUser;
