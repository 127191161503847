import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStores } from "_common/hooks";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "pages/ChatAdmin/components/Dropdown";
import { useChat } from "_common/component/ChatProvider";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";
import ModalEditGroup from "./ModalEditGroup";
import ModalConfirmDelete from "./ModalConfirmDelete";
import ModalManager from "./ModalManager";
import ModalAddMember from "./ModalAddMember";
import { GroupApi } from "../_api/group";

function HeaderChat({ data }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pushChat } = useChat();
  const {
    authStore: { user },
  } = useStores();

  const [openModalEdit, setModalEdit] = useState(false);
  const [openModalDelete, setModalDelete] = useState(false);
  const [openModalManager, setModalManager] = useState(false);
  const [openModalAddMember, setModalAddMember] = useState(false);

  const { mutate: onDelete } = useMutation(
    (variables) => GroupApi.delete({ id: data?.group_id }),
    {
      onSuccess: (res) => {
        pushChat({
          type: "delete_group",
          group_code: data?.group_code,
        });
        NotificationManager.success(i18next.t("group_successfully_deleted"));
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const { mutate: onMuteChat } = useMutation(
    (variables) =>
      GroupApi.blockMember({
        params: {
          id: data?.group_id,
          user_id: data?.user_id,
          is_block: data?.is_block === 0 ? 1 : 0,
        },
      }),
    {
      onSuccess: (res) => {
        pushChat({
          type: "block_member",
          group_code: data?.group_code,
          user_id: data?.user_id,
        });
        NotificationManager.success(
          data?.is_block === 0
            ? i18next.t("member_successfully_blocked")
            : i18next.t("allow_members_to_chat_successfully")
        );
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  return (
    <div className="p-2 user-chat-topbar">
      <div className="row align-items-center">
        <div className="col-sm-8 col-8">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <div className="d-flex align-items-center ">
                <button
                  type="button"
                  className="btn btn-back"
                  onClick={() => navigate("/admin")}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div
                  onClick={() =>
                    data?.type === "group" && setModalManager(true)
                  }
                  className="flex-shrink-0 avatar chat-user-img  avatar-xs online user-own-img align-self-center me-3 ms-0"
                >
                  <img
                    className="rounded-circle"
                    src={data?.avatar_url}
                    alt=""
                  />
                  <span className="user-status" />
                </div>
                <div
                  className="flex-grow-1 overflow-hidden"
                  onClick={() =>
                    data?.type === "group" && setModalManager(true)
                  }
                >
                  <h5
                    role="presentation"
                    className="text-truncate mb-0  mt-2 fs-16"
                  >
                    <span className="text-reset username">
                      {data?.name || data?.username}
                    </span>
                  </h5>
                  {data?.user_total && (
                    <p className="text-truncate text-muted mb-0 userStatus">
                      <small>
                        {data?.user_total} {i18next.t("member")}
                      </small>
                    </p>
                  )}
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p role="presentation" className=" mb-0  mt-2 fs-16">
                    {data?.ip} - {data?.device}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-4">
          <div className="list-inline user-chat-nav text-end mb-0">
            <li className="list-inline-item m-0">
              <Dropdown>
                {data?.type === "user" && (
                  <>
                    <div className="dropdown-item" onClick={() => onDelete()}>
                      {i18next.t("delete_chat")}
                    </div>
                    {/* <div className="dropdown-item" onClick={() => onMuteChat()}>
                      {i18next.t("block_users")}
                    </div> */}
                  </>
                )}
                {data?.type === "group" && (
                  <div>
                    {user?.id === data?.created_by ? (
                      <>
                        <div
                          className="dropdown-item"
                          onClick={() => setModalEdit(true)}
                        >
                          {i18next.t("edit")}
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => setModalAddMember(true)}
                        >
                          {i18next.t("add_member")}
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => setModalManager(true)}
                        >
                          {i18next.t("group_management")}
                        </div>
                        <div
                          className="dropdown-item red"
                          onClick={() => setModalDelete(true)}
                        >
                          {i18next.t("delete_group")}
                        </div>
                      </>
                    ) : (
                      <div
                        className="dropdown-item red"
                        onClick={() => setModalDelete(true)}
                      >
                        {i18next.t("leave_group")}
                      </div>
                    )}
                  </div>
                )}
              </Dropdown>
            </li>
          </div>
        </div>
      </div>
      {openModalEdit && (
        <ModalEditGroup data={data} onClose={() => setModalEdit(false)} />
      )}
      {openModalDelete && (
        <ModalConfirmDelete data={data} onClose={() => setModalDelete(false)} />
      )}
      {openModalManager && (
        <ModalManager data={data} onClose={() => setModalManager(false)} />
      )}
      {openModalAddMember && (
        <ModalAddMember data={data} onClose={() => setModalAddMember(false)} />
      )}
    </div>
  );
}

export default HeaderChat;
