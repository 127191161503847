import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef } from "react";
import { useStores } from "_common/hooks";

import notFound from "pages/ChatClient/_styles/img/icon_noMessage.svg";

import MessageLeft from "./MessageLeft";
import MessageRight from "./MessageRight";

function Conversation({ data, group, time_update, onDelete }) {
  const messagesEndRef = useRef(null);
  const {
    authStore: { user },
  } = useStores();

  useEffect(() => {
    if (!data?.length) return;
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [data, time_update]);

  if (!data?.length)
    return (
      <div className="chat-conversation p-3 p-lg-4 ">
        <div className="simplebar-wrapper">
          <div className="simplebar-mask">
            <div className="simplebar-offset">
              <div className="simplebar-content-wrapper">
                <div className="chat-notfound">
                  <img src={notFound} alt="" />
                  {!group?.username ? (
                    <p>Không có quyền truy cập</p>
                  ) : (
                    <p>Chưa có đoạn hội thoại nào</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="chat-conversation p-3 p-lg-4 ">
      <div className="simplebar-wrapper">
        <div className="simplebar-mask">
          <div className="simplebar-offset">
            <div className="simplebar-content-wrapper">
              <div className="simplebar-content">
                <ul className="list-unstyled chat-conversation-list">
                  {data.map((item, index) => {
                    if (item?.user_id === user?.id || !item?.user_id)
                      return (
                        <MessageRight
                          key={`item-${index.toString()}`}
                          item={item}
                          onDelete={onDelete}
                        />
                      );
                    return (
                      <MessageLeft
                        key={`item-${index.toString()}`}
                        group={group}
                        item={item}
                        onDelete={onDelete}
                      />
                    );
                  })}
                </ul>
                <div ref={messagesEndRef} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conversation;
