import React from "react";
import { Routes, Route } from "react-router-dom";

import UnAuthLayout from "_common/component/Layout/UnAuthLayout";
import PageNotfound from "_common/component/PageNotfound";

// auth
import LoginPage from "pages/Auth/Login";

import CheckAuth from "routers/CheckAuth";

import SupportLayout from "_common/component/Layout/SupportLayout";
import ChatAdminPage from "pages/ChatAdmin";
import ChatClientPage from "pages/ChatClient";
import ClientSupportLayout from "_common/component/Layout/ClientSupportLayout";

export function Routers() {
  return (
    <Routes>
      <Route path="/" element={<ClientSupportLayout />}>
        <Route index element={<ChatClientPage />} />
        <Route path="/admin" element={<ChatAdminPage />} />
      </Route>
      <Route path="/admin" element={<SupportLayout />}>
        <Route index element={<ChatAdminPage />} />
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<LoginPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
