import React, { useState } from "react";
import moment from "moment";

import Dropdown from "pages/ChatClient/components/Dropdown";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChatApi } from "pages/ChatClient/_api/chat";
import { NotificationManager } from "react-notifications";
import { useStores } from "_common/hooks";
import { useChat } from "_common/component/ChatProvider";
import ModalEditChat from "./ModalEditChat";

function MenuChat({ item }) {
  const queryClient = useQueryClient();
  const {
    authStore: { user },
  } = useStores();
  const { pushChat } = useChat();

  const [openEditChat, setOpenEditChat] = useState();

  const { mutate: onDeleteChat, isLoading } = useMutation(
    () => ChatApi.delete({ id: item?.id }),
    {
      onSuccess: (res) => {
        pushChat({
          type: "delete_chat",
          id: item?.id,
        });
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  return (
    <div className="dropdown align-self-start message-box-drop">
      <Dropdown>
        {user.id === item?.user_id && (
          <div className="dropdown-item" onClick={() => setOpenEditChat(true)}>
            Chỉnh sửa
          </div>
        )}
        {/* <div className="dropdown-item">Trả lời</div>
        <div className="dropdown-item">Sao chép</div> */}
        {(user?.id === item?.user_id || user?.role === "admin") && (
          <div className="dropdown-item red" onClick={() => onDeleteChat()}>
            Xoá
          </div>
        )}
      </Dropdown>
      {openEditChat && (
        <ModalEditChat onClose={() => setOpenEditChat(false)} data={item} />
      )}
    </div>
  );
}

export default MenuChat;
