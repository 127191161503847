import classNames from "classnames";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCommentSlash } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import MenuUser from "./MenuUser";

function ListDataUser({
  data,
  group,
  onClose,
  ids = [],
  isRedirect = false,
  isSelect = false,
  onSelect,
}) {
  const navigate = useNavigate();
  const handleStartChat = (item) => {
    if (typeof onSelect === "function") {
      onSelect(item);
    }
    if (isRedirect) {
      if (typeof onClose === "function") {
        onClose();
      }
      navigate({
        pathname: "/admin",
        search: `?username=${item?.username}`,
      });
    }
  };

  if (!data?.length)
    return (
      <div className="px-2">
        <h5>{i18next.t("there_are_no_searches")}</h5>
      </div>
    );

  return (
    <div>
      {data?.map((item) => (
        <li
          key={item.id}
          className={classNames({
            active: ids?.findIndex((key) => key === item?.id) > -1,
          })}
        >
          <div
            onClick={() => handleStartChat(item)}
            className={classNames("item")}
          >
            <div className="d-flex align-items-center">
              <div
                className={classNames(
                  "flex-shrink-0 chat-user-img  align-self-center me-2 ms-0"
                )}
              >
                <div className="avatar avatar-s">
                  <img
                    src={item?.avatar_url}
                    className="rounded-circle img-fluid userprofile"
                    alt=""
                  />
                  <span className="user-status" />
                </div>
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-truncate mb-0">{item?.full_name}</p>
              </div>
              {group && (
                <div className="flex-grow-1 overflow-hidden text-right">
                  <MenuUser item={item} group={group} onClose={onClose} />
                </div>
              )}
            </div>
          </div>
        </li>
      ))}
    </div>
  );
}

export default ListDataUser;
