import React from "react";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { AuthApi } from "states/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import Loading from "_common/component/Loading";

import { useTranslation } from "react-i18next";
import InputPassword from "_common/component/InputPassword";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require")),
    password: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function LoginForm() {
  const { t, i18n } = useTranslation();
  const { authStore } = useStores();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
        // i18n.changeLanguage("vi");
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-sign-in" onSubmit={handleSubmit(handleLogin)}>
      {isLoading && <Loading />}
      <div className="login-header w-full my-[50px] flex justify-center">
        <img src={authStore.logo} alt="" className="w-[100px] h-[45px]" />
      </div>
      <div className="sbui-space-col sbui-space-y-6 px-[25px]">
        <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive !mt-[0]">
          <div className="sbui-formlayout__content-container-horizontal">
            <div className="sbui-input-container">
              <input
                type="text"
                placeholder={i18next.t("username_login")}
                className="sbui-input w-full"
                {...register("username")}
              />
            </div>
          </div>
        </div>
        <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
          <div className="sbui-formlayout__content-container-horizontal">
            <div className="sbui-input-container">
              <InputPassword
                placeholder={i18next.t("password")}
                className="sbui-input w-full"
                {...register("password")}
              />
            </div>
          </div>
        </div>
        <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
          <div className="sbui-formlayout__content-container-horizontal">
            <div className="sbui-input-container">
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="save"
                className="mr-[10px]"
                defaultChecked
              />
              <span className="text-[12px] text-[#999]">
                {i18next.t("remember_password")}
              </span>
            </div>
          </div>
        </div>
        <span className="sbui-btn-container sbui-btn--w-full">
          <button
            className="text-[16px] text-white text-[600] !bg-[#feb23e] w-full min-h-[47px] rounded-[6px]"
            type="submit"
            disabled={!isValid || isLoading}
          >
            <span>{i18next.t("login")}</span>
          </button>
        </span>
        <div className="flex justify-center">
          <div className="text-[12px] text-[#999]">
            <span onClick={() => navigate("/auth/register")}>
              {i18next.t("register")}
            </span>
          </div>
          <div className="text-[12px] text-[#999] mx-[5px]">
            <span>|</span>
          </div>
          <div className="text-[12px] text-[#999]">
            <span onClick={() => navigate("/cskh")}>
              {i18next.t("support")}
            </span>
          </div>
        </div>
      </div>
    </form>
  );
}
