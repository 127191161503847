import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { BrowserRouter, useSearchParams } from "react-router-dom";
import { AuthApi } from "states/api";

import { Routers } from "routers";
import { useStores } from "_common/hooks";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

const basename = document.querySelector("base")?.getAttribute("href") ?? "/";

const Root = observer(() => {
  const { t, i18n } = useTranslation();
  const {
    authStore: { device, language, setInitData, updateUser },
  } = useStores();

  useQuery(["get_device"], AuthApi.getDeviceCode, {
    cacheTime: 300000,
    staleTime: 300000,
    onSuccess: (data) => {
      setInitData(data);
    },
  });

  useEffect(() => {
    try {
      i18n.changeLanguage(language);
    } catch (error) {
      //
    }
  }, [language]);

  return (
    <BrowserRouter basename={basename}>
      <Routers />
    </BrowserRouter>
  );
});
export default Root;
