import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import ListFriend from "./ListFriend";
import ModalAddGroup from "./ModalAddGroup";
import SearchUser from "./SearchUser";

function LeftBar() {
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();
  const [params] = useSearchParams();
  const {
    authStore: { user },
  } = useStores();

  const username = params.get("username");

  const [isSearch, setIsSearch] = useState(false);

  if (isSearch)
    return (
      <div className="chat-leftsidebar">
        <div className="mt-4">
          <SearchUser onClose={() => setIsSearch(false)} />
        </div>
      </div>
    );

  return (
    <div
      className="chat-leftsidebar"
      style={{ display: isMobile && username && "none" }}
    >
      <div className="px-2 pt-4 mb-2">
        <div className="search-box">
          <input
            type="text"
            className="form-control bg-light border-light"
            placeholder={i18next.t("search_for_users")}
            onClick={() => setIsSearch(true)}
          />
          <i className="ri-search-2-line search-icon" />
        </div>
      </div>
      <ListFriend />
    </div>
  );
}

export default LeftBar;
