import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function InputPassword({ ...props }, ref) {
  const inputRef = React.useRef(null);

  React.useImperativeHandle(ref, () => inputRef.current);

  const [open, setOpen] = useState(false);
  return (
    <div className="control-wrapper">
      <input
        {...props}
        autoComplete="current-password"
        ref={inputRef}
        type={open ? "text" : "password"}
      />
      <FontAwesomeIcon
        icon={open ? faEye : faEyeSlash}
        onClick={() => setOpen(!open)}
      />
    </div>
  );
}

export default React.forwardRef(InputPassword);
