import React, { useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Portal } from "react-portal";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { GroupApi } from "pages/ChatAdmin/_api/group";
import { useChat } from "_common/component/ChatProvider";
import { NotificationManager } from "react-notifications";
import SearchUser from "../LeftBar/SearchUser";

function ModalAddMember({ onClose, data: group }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general },
  } = useStores();
  const { pushChat } = useChat();

  const [lstUser, setLstUser] = useState([]);

  const { mutate: onSubmit } = useMutation(
    (variables) => GroupApi.addMember({ params: variables }),
    {
      onSuccess: (res) => {
        onClose();
        pushChat({
          type: "add_member",
          group_id: group?.id,
        });
        NotificationManager.success(
          i18next.t("new_chat_group_successfully_created")
        );
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSelect = (item) => {
    const cloneData = [...lstUser];

    const checkIndex = cloneData.findIndex((obj) => obj.id === item?.id);

    if (checkIndex > -1) {
      cloneData.splice(checkIndex, 1);
    } else {
      cloneData.push(item);
    }

    setLstUser(cloneData);
  };

  const handleSubmit = () => {
    if (lstUser?.length < 1)
      return NotificationManager.error(
        i18next.t("select_at_least_1_group_member")
      );

    const req = {
      group_id: group?.id,
      ids: lstUser?.map((item) => item?.id),
    };

    onSubmit(req);

    return null;
  };

  return (
    <Portal>
      <div className="modal">
        <div className="modal-mask" />
        <div className="modal-root">
          <div className="modal-title">
            <h4>{i18next.t("add_chat_group")}</h4>
          </div>
          <div className="modal-content pb-24">
            <p className="mt-2 mb-1">
              <b>{i18next.t("select_user")}</b>
            </p>
            <div className="list-select-user">
              {lstUser?.map((item) => (
                <div className="item" key={item?.id}>
                  {item?.full_name}
                  <span
                    className="btn-destroy"
                    onClick={() => handleSelect(item)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
              ))}
            </div>
            <SearchUser
              isSelect
              api_type="add_member"
              group={group}
              onSelect={handleSelect}
              lstUser={lstUser}
            />
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button type="button" className="btn-cancel" onClick={onClose}>
                {i18next.t("close")}
              </button>
              <button
                type="button"
                className="btn-contact-cskh"
                onClick={handleSubmit}
              >
                {i18next.t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalAddMember;
