import React, { useEffect, useMemo, useState } from "react";
import { get, isDate, reverse } from "lodash";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FriendApi } from "pages/ChatClient/_api/friend";
import { useChat } from "_common/component/ChatProvider";
import { useStores } from "_common/hooks";
import { NotificationManager } from "react-notifications";
import { ChatApi } from "pages/ChatClient/_api/chat";
import { serverUrl } from "_common/constants/common";
import { useNotify } from "_common/component/NotifyProvider";
import { observer } from "mobx-react-lite";

import ChatInput from "./ChatInput";
import Conversation from "./Conversation";

const MessageBoard = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    authStore: { user, device, general },
  } = useStores();
  const { isMobile } = useMediaQuery();
  const [params] = useSearchParams();
  const {
    time_update,
    current_room,
    notifyData,
    pushChat,
    chatData,
    setCurrentRoom,
    sendChat,
  } = useChat();
  const { pushNotify } = useNotify();

  const username = user?.username || device;
  const [listChats, setListChat] = useState([]);

  const { data, refetch } = useQuery(
    ["user", "detail_user", username],
    () =>
      FriendApi.getDetailUser({
        params: {
          username,
        },
      }),
    {
      staleTime: 300000,
      enabled: !!username,
    }
  );

  const group_code = useMemo(
    () => data?.group_code || username,
    [data, username]
  );

  const { data: dataChat, refetch: getListChat } = useQuery(
    ["chat", "list", group_code, params],
    () =>
      ChatApi.getList({
        params: {
          page: 1,
          limit: 200,
          group_code,
          username,
          ...params,
        },
      }),
    {
      staleTime: 300000,
      enabled: !!group_code,
    }
  );

  const handleChatSuccess = (res) => {
    //
    sendChat({
      type: "sync_data",
      username,
      room_id: current_room || username,
      group_code: res?.group_code,
    });
    queryClient.invalidateQueries(["user", "detail_user"]);
    pushNotify({
      type: "new_chat",
    });
    if (!group_code) {
      refetch();
      pushChat({
        type: "new_group",
      });
    }
  };

  const { mutate: onSendMessage } = useMutation(
    (variables) => ChatApi.sendMessage({ params: variables }),
    {
      onSuccess: (res) => {
        handleChatSuccess({ group_code: res?.data?.group_code });
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    const result = get(dataChat, "data", [])?.map((item) => ({
      id: item?.id,
      user_id: item?.created_by,
      full_name: item?.user?.full_name,
      avatar: item?.user?.avatar,
      file_path: item?.media_id && `${serverUrl}/${item?.media?.file_path}`,
      message: item?.message,
      status: item?.status,
      type: item?.type,
      created_at: item?.created_at,
    }));
    setListChat(reverse(result));
  }, [dataChat]);

  useEffect(() => {
    if (!username) return;
    if (data?.type === "group") {
      setCurrentRoom(username);
    } else if (data?.type === "user") {
      setCurrentRoom(group_code);
    } else if (username) {
      setCurrentRoom(username);
    } else {
      setCurrentRoom(device);
    }
  }, [data, group_code, device, username]);

  useEffect(() => {
    if (!username) return;
    refetch();
  }, [username]);

  useEffect(() => {
    if (!group_code) return;
    getListChat();
  }, [group_code]);

  useEffect(() => {
    const dataClone = [...listChats];
    if (chatData?.room_id === current_room) {
      const chatItem = get(chatData, "data[0]");
      if (chatItem) {
        if (chatItem?.status !== "block" || chatItem?.user_id === user?.id) {
          dataClone.push({
            ...chatItem,
            file_path:
              chatItem?.file_path && `${serverUrl}/${chatItem?.file_path}`,
          });
          setListChat(dataClone);
        }
      }
    }
  }, [chatData]);

  useEffect(() => {
    if (
      [
        "new_group",
        "delete_group",
        "delete_member",
        "edit_group",
        "add_member",
      ].indexOf(notifyData?.type) > -1
    ) {
      refetch();
      queryClient.invalidateQueries(["group", "list"]);
      queryClient.invalidateQueries(["chat", "list"]);
    }

    if (notifyData?.type === "delete_chat") {
      const cloneData = [...listChats];
      const checkIndex = cloneData?.findIndex(
        (obj) => obj.id === notifyData?.id
      );
      if (checkIndex > -1) {
        cloneData.splice(checkIndex, 1);
        setListChat(cloneData);
      }
    }

    if (notifyData?.type === "edit_chat") {
      const cloneData = [...listChats];
      const checkIndex = cloneData?.findIndex(
        (obj) => obj.id === notifyData?.id
      );
      if (checkIndex > -1) {
        cloneData[checkIndex] = {
          ...cloneData[checkIndex],
          message: notifyData?.message,
          status: "edit",
        };
        setListChat(cloneData);
      }
    }

    if (notifyData?.type === "new_group") {
      queryClient.invalidateQueries(["user", "detail_user"]);
    }

    if (
      notifyData?.type === "edit_group" &&
      notifyData?.group_code === group_code
    ) {
      queryClient.invalidateQueries(["user", "detail_user"]);
    }

    if (
      notifyData?.type === "block_member" &&
      notifyData?.group_code === group_code &&
      notifyData?.user_id === user?.id
    ) {
      refetch();
    }
  }, [notifyData, group_code]);

  const handleSubmitChat = (msg) => {
    onSendMessage({
      room_id: username,
      user_id: data?.user_id,
      type: data?.type || "user",
      message: msg,
    });
  };

  return (
    <div
      className="user-chat w-100 overflow-hidden user-chat-show"
      style={{ display: !username && "none" }}
    >
      <div className="chat-content d-lg-flex">
        <div className="w-100 overflow-hidden position-relative">
          <div className="position-relative">
            <div className="position-relative">
              <Conversation
                group={data}
                data={[
                  {
                    id: 0,
                    user_id: 1,
                    full_name: "CSKH",
                    message:
                      general?.welcome_msg ||
                      "Xin chào! Chúng tôi có thể giúp gì cho bạn?",
                    status: "read",
                    type: "user",
                    created_at: new Date(),
                  },
                  ...listChats,
                ]}
                time_update={time_update}
              />
            </div>
            {data?.id && (
              <ChatInput
                onSubmit={handleSubmitChat}
                onChatSuccess={handleChatSuccess}
                detail={{
                  room_id: username,
                  type: data?.type || "user",
                  user_id: data?.id,
                  group_code: data?.group_code,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default MessageBoard;
