import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import i18next from "i18next";
import avatar2 from "pages/ChatAdmin/_styles/img/group.png";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { GroupApi } from "pages/ChatAdmin/_api/group";
import { get } from "lodash";
import { useStores } from "_common/hooks";

function ListFriend() {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();
  const [active, setActive] = useState();
  const handleStartChat = (username) => {
    navigate({
      pathname: "/admin",
      search: `?username=${username}`,
    });
  };

  const { data: dataGroup, refetch: getListGroup } = useQuery(
    ["group", "list"],
    () =>
      GroupApi.getList({
        params: {
          page: 1,
          limit: 15,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    getListGroup();
  }, []);

  const renderListData = useMemo(() => {
    const listData = get(dataGroup, "data", []);
    if (!listData?.length)
      return (
        <div className="chat-message-list">
          <div className="list-unstyled chat-list chat-user-list px-2">
            <h5>{i18next.t("there_has_been_no_conversation_yet")}</h5>
          </div>
        </div>
      );

    return (
      <>
        {listData.map((item) => {
          let room_id = item?.code;
          let group_name = item?.name;
          let group_avatar = avatar2;
          if (item?.type === "user") {
            const users = get(item, "users", []);
            const itemUser = users.find((obj) => obj?.user_id !== user?.id);
            if (itemUser) {
              room_id = get(itemUser, "user.username");
              group_name = get(itemUser, "user.username");
              group_avatar = get(itemUser, "user.avatar_url");
            }
          }
          return (
            <li
              className={classNames({ active: active === item.id })}
              key={item.id}
            >
              <div className="item" onClick={() => handleStartChat(room_id)}>
                <div className="d-flex align-items-center">
                  <div
                    className={classNames(
                      "flex-shrink-0 chat-user-img  align-self-center me-2 ms-0",
                      {
                        online: item.new > 0,
                      }
                    )}
                  >
                    <div className="avatar avatar-s">
                      <img
                        src={group_avatar}
                        className="rounded-circle img-fluid userprofile"
                        alt=""
                      />

                      <span className="user-status" />
                    </div>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">{group_name}</p>
                  </div>
                  {item.new > 0 && (
                    <div className="ms-auto">
                      <span className="badge badge-soft-dark rounded p-1">
                        {item.new}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </>
    );
  }, [dataGroup]);

  return (
    <div className="chat-message-list">
      <div className="list-unstyled chat-list chat-user-list">
        {renderListData}
      </div>
    </div>
  );
}

export default ListFriend;
