import React, { useEffect, useMemo, useState } from "react";
import { debounce, get } from "lodash";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Portal } from "react-portal";
import { GroupApi } from "pages/ChatAdmin/_api/group";
import i18next from "i18next";
import { useChat } from "_common/component/ChatProvider";
import { ChatApi } from "pages/ChatAdmin/_api/chat";
import ListDataUser from "../LeftBar/SearchUser/ListDataUser";

function ModalManager({ onClose, data }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general },
  } = useStores();
  const { pushChat } = useChat();

  const [content, setContent] = useState("");
  const [params, setParams] = useState("");

  const { data: listMember, refetch } = useQuery(
    ["group", "list_member", params],
    () =>
      GroupApi.getListMember({
        params: {
          page: 1,
          limit: 13,
          id: data?.id,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const onEnterSearch = debounce(
    (keyword) => {
      setParams({ keyword });
    },
    [1000]
  );

  const handleChangeInput = (event) => {
    onEnterSearch(event?.target?.value);
  };

  return (
    <Portal>
      <div className="modal">
        <div className="modal-mask" />
        <div className="modal-root">
          <div className="modal-title">
            <h4>
              {data?.user_total} {i18next.t("member")}
            </h4>
          </div>
          <div className="modal-content pb-24">
            <div className="search-box-group">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control bg-light border-light"
                  placeholder={i18next.t("search_for_users")}
                  onChange={handleChangeInput}
                />
                <i className="ri-search-2-line search-icon" />
              </div>
            </div>
            <div className="chat-message-list">
              <div className="list-unstyled chat-list chat-user-list">
                <ListDataUser
                  data={get(listMember, "data", [])}
                  group={data}
                  onClose={onClose}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button type="button" className="btn-cancel" onClick={onClose}>
                {i18next.t("close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalManager;
