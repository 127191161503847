import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import io from "socket.io-client";
import { socketUrl } from "_common/constants/common";
import { useQueryClient } from "@tanstack/react-query";

let sock = null;

export const NotifyContext = createContext({});

export default function NotifyProvider({ children }) {
  const queryClient = useQueryClient();

  const [notify, setNotify] = useState(null);

  const onConnectSocket = (e) => {
    console.log(e);
  };

  const pushNotify = (data) => {
    sock.emit("push_notify", {
      data,
    });
  };

  const playSound1Chat = () => {
    try {
      const sound = document.getElementById("sound3");
      if (sound) {
        sound.muted = false;
        sound.loop = true;
        sound.play();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const pauseSound = () => {
    try {
      const sound3 = document.getElementById("sound3");
      if (sound3) {
        sound3.muted = true;
        sound3.loop = false;
        sound3?.pause();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onGetNotify = (data) => {
    setNotify(data);
    if (data?.data?.type === "new_chat") {
      playSound1Chat();
      setTimeout(() => {
        pauseSound();
      }, 3000);
    }
  };

  useEffect(() => {
    sock = io(socketUrl, {
      transports: ["websocket"],
    });

    sock.on("open", onConnectSocket);
    sock.on("push_notify", onGetNotify);
    return () => {
      sock.close();
    };
  }, []);

  const returnDefault = useMemo(
    () => ({
      pushNotify,
    }),
    [notify]
  );

  return (
    <NotifyContext.Provider value={returnDefault}>
      {children}
    </NotifyContext.Provider>
  );
}

export function useNotify() {
  return useContext(NotifyContext);
}
