import classNames from "classnames";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faComment,
  faCommentSlash,
} from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { GroupApi } from "pages/ChatAdmin/_api/group";
import { useChat } from "_common/component/ChatProvider";
import { useStores } from "_common/hooks";
import { NotificationManager } from "react-notifications";

function MenuUser({ item, onClose, group }) {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  const { pushChat } = useChat();

  const { mutate: onDelete } = useMutation(
    (variables) =>
      GroupApi.deleteMember({
        params: {
          id: group?.id,
          user_id: item?.user_id,
        },
      }),
    {
      onSuccess: (res) => {
        onClose();
        pushChat({
          type: "delete_member",
          group_code: group?.group_code,
          user_id: item?.user_id,
        });
        NotificationManager.success(
          i18next.t("member_successfully_removed_from_group")
        );
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const { mutate: onMuteChat } = useMutation(
    (variables) =>
      GroupApi.blockMember({
        params: {
          id: group?.id,
          user_id: item?.user_id,
          is_block: item?.is_block === 0 ? 1 : 0,
        },
      }),
    {
      onSuccess: (res) => {
        onClose();
        pushChat({
          type: "block_member",
          group_code: group?.group_code,
          user_id: item?.user_id,
        });
        NotificationManager.success(
          item?.is_block === 0
            ? i18next.t("member_successfully_blocked")
            : i18next.t("allow_members_to_chat_successfully")
        );
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  if (item?.user_id === group?.created_by)
    return <div>{i18next.t("group_owner")}</div>;
  if (user?.id === item?.user_id) return <div />;
  if (user?.id !== group?.created_by) return <div />;

  return (
    <>
      <button
        type="button"
        className="btn  p-1"
        title={i18next.t("no_chat")}
        onClick={onMuteChat}
      >
        <FontAwesomeIcon
          icon={item?.is_block === 0 ? faComment : faCommentSlash}
        />
      </button>
      <button
        type="button"
        className="btn p-1 red "
        title={i18next.t("kick_from_group")}
        onClick={onDelete}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </>
  );
}

export default MenuUser;
