import React from "react";
import LoginForm from "./LoginForm";

function LoginPage() {
  return (
    <>
      <div className="h-[44px]" />
      <LoginForm />
    </>
  );
}

export default LoginPage;
